import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Main } from "./pages/Main";

function App() {
  return (
    <Router>
      <Routes>
        {/* Редирект на главную */}
        <Route path="/" element={<Main />} />
        {/* Редирект */}
        <Route path="*" element={<RedirectToExternalSite />} />
      </Routes>
    </Router>
  );
}

const RedirectToExternalSite = () => {
  const location = useLocation();
  window.location.href = `https://rpnltd.ru/api/redirect${location.pathname}`;
  return null;
};

export default App;
